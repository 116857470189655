// import "babel-polyfill";
import domready from "domready"

domready(function () {
  var OPEN_MENU_ID = "svg.menu-open";
  var CLOSE_MENU_ID = ".menu-close";
  var MENU_ID = "header nav";
  var HIDDEN_CLASS = "hidden";

  const mobileMenuIcon = document.querySelector(".menu-open");
  const mobileCloseIcon = document.querySelector(".menu-close");

  const dropdowns = [...document.querySelectorAll(".dropdown-root")].map(root => ({
    root,
    trigger: root.querySelector("[data-dropdown-element=trigger]"),
  }));

  /* Mobile Menu toggling */
  mobileMenuIcon.addEventListener("click", function () {
    document.body.classList.add('freeze');
    
    // Set dropdown menu default state to open on mobile menu open
    for (const {root} of dropdowns) {
      root.classList.add('toggled');
      root.setAttribute("aria-expanded", "true");
    }
  });

  mobileCloseIcon.addEventListener("click", function () {
    document.body.classList.remove('freeze');
  });

  /* Dropdown Menu hovering */

  for (const {root, trigger} of dropdowns) {
    root.addEventListener("mouseenter", function () {
      // Only trigger hovering on resolutions > mobile
      if (window.innerWidth >= 768) {
        root.setAttribute("aria-expanded", "true");
      }
    });

    root.addEventListener("mouseleave", function () {
      // Only trigger hovering on resolutions > mobile
      if (window.innerWidth >= 768) {
        if (!root.classList.contains('toggled'))
          root.setAttribute("aria-expanded", "false");
      }
    });

    // Toggle Dropdown Menu on click, overrides hover behavior
    trigger.addEventListener("click", function () {
      root.classList.toggle('toggled');
      root.setAttribute("aria-expanded", root.classList.contains('toggled') ? "true" : "false");
    });

    // Close Dropdown Menu when clicking outside
    document.addEventListener("click", function (event) {
      if (!trigger.contains(event.target) && !mobileMenuIcon.contains(event.target) && root.getAttribute("aria-expanded") === "true") {
        root.setAttribute("aria-expanded", "false");
        root.classList.remove('toggled');
      }
    });
  }

  function hasClass(el, name) {
    var re = new RegExp("(?:^|\\s)" + name + "(?!\\S)");
    return el.className.match(re);
  }

  function addClass(el, name) {
    el.className += " " + name;
  }

  function removeClass(el, name) {
    var re = new RegExp("(?:^|\\s)" + name + "(?!\\S)", "g");
    el.className = el.className.replace(re, "");
  }

  function toggleMenu(e) {
    e.preventDefault();
    var menu = document.querySelector(MENU_ID);
    if (hasClass(menu, HIDDEN_CLASS)) {
      removeClass(menu, HIDDEN_CLASS);
    } else {
      addClass(menu, HIDDEN_CLASS);
    }
  }

  function get_csrf_token() {
    var cookie_name = "csrf_access_token";
    var value = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)');
    return value ? value[2] : null;
  }

  document.querySelector(OPEN_MENU_ID).addEventListener("click", toggleMenu);
  document
    .querySelector(CLOSE_MENU_ID)
    .addEventListener("click", toggleMenu);

  window.FS_UTILS = { get_csrf_token: get_csrf_token };

});


  
